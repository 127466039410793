/* 患者管理主页面 */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { makeStyles, withStyles, withTheme } from '@material-ui/core/styles';
import { Route, Switch } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Button from '@material-ui/core/Button';

import returnImg from '../assets/images/returnImg.png'
import Toast from "antd-mobile/lib/toast";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import Api from '../redux/api/api';
import axios from "axios";


import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import { Upload, message, Icon } from 'antd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

// 引入antd 时间组件
import Moment from 'moment';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../assets/css/border.css';


const steps = ["问诊筛查", "注射化验", "结果上报"];

const BeautyFormControlLabel = withStyles({
    root: {
        margin: 0,
    },
    label: {
        fontSize: '1rem',
        marginLeft: '-1.8vw',
        color: 'black',
        fontWeight: 395,
    },
})(FormControlLabel);

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 14,
        height: 14,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 14,
            height: 14,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

const GreenCheckbox = withStyles({
    root: {
        color: "#00C9E4",
        "&$checked": {
            color: "#00C9E4"
        }
    },
    checked: {}
})(props => <Checkbox color="default" {...props} />);

function StyledRadio(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

//医生端主页面
class ScreenCheckView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            schoolName: '清华附中',
            name: '果冻',
            className: '27级别高三2班',
            ppdTime: null,
            ppdHide: true,
            ecHide: true,
        };
        this.handleReturnClick = this.handleReturnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.pickerPpdCheckTime = this.pickerPpdCheckTime.bind(this);
        this.pickerPpdCheckResultTime = this.pickerPpdCheckResultTime.bind(this);
        this.pickerEcCheckTime = this.pickerEcCheckTime.bind(this);
        this.pickerEcCheckResultTime = this.pickerEcCheckResultTime.bind(this);
        this.pickerIgraCheckTime = this.pickerIgraCheckTime.bind(this);
        this.pickerIgraCheckResultTime = this.pickerIgraCheckResultTime.bind(this);

        this.handleClickOk = this.handleClickOk.bind(this);

        this.handleClose = this.handleClose.bind(this)
        this.confirmRemoveImg = this.confirmRemoveImg.bind(this)
        this.clickeImg = this.clickeImg.bind(this)
        this.addScreenImage = this.addScreenImage.bind(this)

        this.handleCheckTypeChange = this.handleCheckTypeChange.bind(this)
    }

    componentDidMount() {
        //const urlParams = new URLSearchParams(window.location.search);
        const patientId = this.props.history.location.state?.patientId;
        if (patientId == null) {
            Toast.show("加载出错，请返回重试！");
            return;
        }
        this.state.patientId = patientId;
        //this.state.activeStep = urlParams.get('activeStep');
        const url = Api.getRootUrl() + '/api/doctor/getScreenCheck'
        let that = this;
        let params = {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            patientId: patientId
        }
        console.log("start get check...")
        axios.post(url, params).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                console.log(rsp.data.data?.screening)
                that.setState({
                    activeStep: rsp.data.data?.activeStep,
                    name: rsp.data.data?.name,
                    schoolName: rsp.data.data?.schoolName,
                    className: rsp.data.data?.className,
                    //...rsp.data.data?.screening
                })
                if (rsp.data.data?.screening) {
                    //that.setState({...rsp.data.data?.screening})
                    //that.setState({hasTuberculosis:1});
                    let filteredData = {};
                    const scData = rsp.data.data?.screening
                    // 过滤掉值为 null 的属性
                    const skipKeys = ["userId"]
                    for (const key in scData) {
                        if(skipKeys.includes(key)) continue;
                        if (scData[key] !== null) {
                            filteredData[key] = scData[key];
                        }
                    }
                    that.setState(filteredData)
                    //手动设置一些属性
                    that.setState({
                        pic: rsp.data.data?.screening?.attachment ? rsp.data.data?.screening?.attachment.split(",") : '', //上传照片
                    })
                    if(rsp.data.data?.activeStep == 2 && filteredData.checkType == 1){//填报时默认
                        that.setState({ppdReact: 0,ppdResult: 1})
                    }
                    if(rsp.data.data?.activeStep == 2 && filteredData.checkType == 2){//填报时默认
                        that.setState({ecReact: 0,ecResult: 2})
                    }
                    if(rsp.data.data?.activeStep == 2 && filteredData.checkType == 4){//填报时默认
                        that.setState({igraResult: 2})
                    }
                    //设置显示隐藏选项
                    if(filteredData.ppdReact == 1){
                        this.setState({ppdHide: false})
                    }
                    if(filteredData.ecReact == 1){
                        this.setState({ecHide: false})
                    }
                }

            } else {
                Toast.info("获取数据失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("网络问题，请稍后再试！")
        })

    }

    componentWillUnmount() {
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.serverInfo !== this.props.serverInfo) {
        }
    }


    handleInputChange(event) {
        console.log(event)
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });

        //自动默认设置-PPD
        let ppdHardenDiameter = this.state.ppdHardenDiameter;
        if(name == "ppdHardenDiameterX"){
            if(value > 0 && this.state.ppdHardenDiameterY > 0){
                ppdHardenDiameter = Math.round(100*(parseFloat(value) + parseFloat(this.state.ppdHardenDiameterY))/2)/100
                this.setState({ppdHardenDiameter: ppdHardenDiameter})
            }
        }
        if(name == "ppdHardenDiameterY"){
            if(value > 0 && this.state.ppdHardenDiameterX > 0){
                ppdHardenDiameter = Math.round(100*(parseFloat(this.state.ppdHardenDiameterX) + parseFloat(value))/2)/100
                this.setState({ppdHardenDiameter: ppdHardenDiameter})
            }
        }
        if(name == "ppdHardenDiameterX" || name == "ppdHardenDiameterY"){
            if(ppdHardenDiameter < 5){
                this.setState({ppdResult: 1})
            }
            if(ppdHardenDiameter >= 5 && ppdHardenDiameter <= 10){
                this.setState({ppdResult: 2})
            }
            if(ppdHardenDiameter >= 10 && ppdHardenDiameter < 15){
                this.setState({ppdResult: 3})
            }
            if(ppdHardenDiameter >= 15){
                this.setState({ppdResult: 4})
            }
        }
        if(name == "ppdHardenDiameter"){
            if(value < 5){
                this.setState({ppdResult: 1})
            }
            if(value >= 5 && value < 10){
                this.setState({ppdResult: 2})
            }
            if(value >= 10 && value < 15){
                this.setState({ppdResult: 3})
            }
            if(value >= 15){
                this.setState({ppdResult: 4})
            }
        }
        if(name == "ppdBlister" || name == "ppdDoubleLoop" || name == "ppdNecrosis" || name == "ppdLymphangitis"){
            if(value == 1){
                this.setState({ppdResult: 4})
            }else if(this.state.ppdResult == 4){//如果之前是4，且症状没有是的，设置成阴性
                if(name == "ppdBlister" && this.state.ppdDoubleLoop != 1 && this.state.ppdNecrosis != 1 && this.state.ppdLymphangitis != 1){
                    //this.setState({ppdResult: 1})
                    if(ppdHardenDiameter == null || ppdHardenDiameter < 5){
                        this.setState({ppdResult: 1})
                    }
                    if(ppdHardenDiameter >= 5 && ppdHardenDiameter < 10){
                        this.setState({ppdResult: 2})
                    }
                    if(ppdHardenDiameter >= 10 && ppdHardenDiameter < 15){
                        this.setState({ppdResult: 3})
                    }
                    if(ppdHardenDiameter >= 15){
                        this.setState({ppdResult: 4})
                    }
                }
                if(name == "ppdDoubleLoop" && this.state.ppdBlister != 1 && this.state.ppdNecrosis != 1 && this.state.ppdLymphangitis != 1){
                    //this.setState({ppdResult: 1})
                    if(ppdHardenDiameter == null || ppdHardenDiameter < 5){
                        this.setState({ppdResult: 1})
                    }
                    if(ppdHardenDiameter >= 5 && ppdHardenDiameter < 10){
                        this.setState({ppdResult: 2})
                    }
                    if(ppdHardenDiameter >= 10 && ppdHardenDiameter < 15){
                        this.setState({ppdResult: 3})
                    }
                    if(ppdHardenDiameter >= 15){
                        this.setState({ppdResult: 4})
                    }
                }
                if(name == "ppdNecrosis" && this.state.ppdBlister != 1 && this.state.ppdDoubleLoop != 1 && this.state.ppdLymphangitis != 1){
                    //this.setState({ppdResult: 1})
                    if(ppdHardenDiameter == null || ppdHardenDiameter < 5){
                        this.setState({ppdResult: 1})
                    }
                    if(ppdHardenDiameter >= 5 && ppdHardenDiameter < 10){
                        this.setState({ppdResult: 2})
                    }
                    if(ppdHardenDiameter >= 10 && ppdHardenDiameter < 15){
                        this.setState({ppdResult: 3})
                    }
                    if(ppdHardenDiameter >= 15){
                        this.setState({ppdResult: 4})
                    }
                }
                if(name == "ppdLymphangitis" && this.state.ppdBlister != 1 && this.state.ppdDoubleLoop != 1 && this.state.ppdNecrosis != 1){
                    //this.setState({ppdResult: 1})
                    if(ppdHardenDiameter == null || ppdHardenDiameter < 5){
                        this.setState({ppdResult: 1})
                    }
                    if(ppdHardenDiameter >= 5 && ppdHardenDiameter < 10){
                        this.setState({ppdResult: 2})
                    }
                    if(ppdHardenDiameter >= 10 && ppdHardenDiameter < 15){
                        this.setState({ppdResult: 3})
                    }
                    if(ppdHardenDiameter >= 15){
                        this.setState({ppdResult: 4})
                    }
                }
            }
        }
        //自动默认设置-EC
        let ecHardenDiameter = this.state.ecHardenDiameter;
        if(name == "ppdHardenDiameterX"){
            if(value > 0 && this.state.ppdHardenDiameterY > 0){
                ecHardenDiameter = Math.round(100*(parseFloat(value) + parseFloat(this.state.ppdHardenDiameterY))/2)/100
                this.setState({ecHardenDiameter: ecHardenDiameter})
            }
        }
        if(name == "ecHardenDiameterY"){
            if(value > 0 && this.state.ecHardenDiameterX > 0){
                ecHardenDiameter = Math.round(100*(parseFloat(this.state.ecHardenDiameterX) + parseFloat(value))/2)/100
                this.setState({ecHardenDiameter: ecHardenDiameter})
            }
        }
        if(name == "ecHardenDiameterX" || name == "ecHardenDiameterY"){
            if(ecHardenDiameter < 5){
                this.setState({ecResult: 2})
            }
            if(ecHardenDiameter >= 5){
                this.setState({ecResult: 1})
            }
        }

        if(name == "ecHardenDiameter"){
            if(value < 5){
                this.setState({ecResult: 2})
            }
            if(value >= 5){
                this.setState({ecResult: 1})
            }
        }


        if(name == "ecBlister" || name == "ecDoubleLoop" || name == "ecNecrosis" || name == "ecLymphangitis"){
            if(value == 1){
                this.setState({ecResult: 1})
            }else if(this.state.ecResult == 1){//如果之前是1，且症状没有是的，设置成阴性
                if(name == "ecBlister" && this.state.ecDoubleLoop != 1 && this.state.ecNecrosis != 1 && this.state.ecLymphangitis != 1){
                    if(ecHardenDiameter == null || ecHardenDiameter < 5){
                        this.setState({ecResult: 2})
                    }else if(ecHardenDiameter >= 5){
                        this.setState({ecResult: 1})
                    }
                }
                if(name == "ecDoubleLoop" && this.state.ecBlister != 1 && this.state.ecNecrosis != 1 && this.state.ecLymphangitis != 1){
                    if(ecHardenDiameter == null || ecHardenDiameter < 5){
                        this.setState({ecResult: 2})
                    }else if(ecHardenDiameter >= 5){
                        this.setState({ecResult: 1})
                    }
                }
                if(name == "ecNecrosis" && this.state.ecBlister != 1 && this.state.ecDoubleLoop != 1 && this.state.ecLymphangitis != 1){
                    if(ecHardenDiameter == null || ecHardenDiameter < 5){
                        this.setState({ecResult: 2})
                    }else if(ecHardenDiameter >= 5){
                        this.setState({ecResult: 1})
                    }
                }
                if(name == "ecLymphangitis" && this.state.ecBlister != 1 && this.state.ecDoubleLoop != 1 && this.state.ecNecrosis != 1){
                    if(ecHardenDiameter == null || ecHardenDiameter < 5){
                        this.setState({ecResult: 2})
                    }else if(ecHardenDiameter >= 5){
                        this.setState({ecResult: 1})
                    }
                }
            }
        }

    }

    handleReturnClick() {
        this.props.history.goBack();
    }

    handleClickOk() {
        if(this.state.activeStep == 1 && this.state.checkType == null){
            Toast.info("化验类型必填！")
            return
        }
        const url = Api.getRootUrl() + '/api/doctor/updateScreenCheck'
        let that = this;
        let params = {
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            doctorScreening: 1,
            ...this.state
        }
        axios.post(url, params).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                Toast.show("提交成功！")
                localStorage.setItem("autoScan", true)
                that.handleReturnClick()

            } else {
                Toast.info("提交失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("网络问题，请稍后再试！")
        })
    }

    pickerPpdCheckTime(date, dateString) {
        this.setState({
            ppdCheckTime: dateString.replace("年", "-").replace("月", "-").replace("日", "")
        })
    }
    pickerPpdCheckResultTime(date, dateString) {
        this.setState({
            ppdCheckResultTime: dateString.replace("年", "-").replace("月", "-").replace("日", "")
        })
    }
    pickerEcCheckTime(date, dateString) {
        this.setState({
            ecCheckTime: dateString.replace("年", "-").replace("月", "-").replace("日", "")
        })
    }
    pickerEcCheckResultTime(date, dateString) {
        this.setState({
            ecCheckResultTime: dateString.replace("年", "-").replace("月", "-").replace("日", "")
        })
    }
    pickerIgraCheckTime(date, dateString) {
        this.setState({
            igraCheckTime: dateString.replace("年", "-").replace("月", "-").replace("日", "")
        })
    }
    pickerIgraCheckResultTime(date, dateString) {
        this.setState({
            igraCheckResultTime: dateString.replace("年", "-").replace("月", "-").replace("日", "")
        })
    }

    clickeImg(imgUrl) {

        this.setState({
            open: true,
            removeImg: imgUrl
        })
    }

    handleClose() {
        this.setState({
            open: false
        })
    }

    confirmRemoveImg() {
        this.setState({
            open: false
        })

        let imgUrl = this.state.removeImg

        //const url = 'http://localhost:8080/api/followUp/removeImg'

        const url = Api.getRootUrl() + '/api/custom/removeScreenImg'
        let params = { id: this.state.id, pic: imgUrl }
        console.log(this.state.pic)

        let pics = this.state.pic.filter(function (item) {
            return item !== imgUrl;
        })
        this.setState({
            pic: pics
        })

        axios.post(url, params).then(function (rsp) {
            console.log(rsp)
            if (rsp.data.code === 200) {

            } else {
                Toast.info("提交失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("提交失败，请稍后再试！")
        })
    }

    addScreenImage(params) {
        console.log(params)
        const url = Api.getRootUrl() + '/api/custom/addScreenImage'
        axios.post(url, params).then(function (rsp) {
            console.log(rsp)
            if (rsp.data.code === 200) {

            } else {
                Toast.info("提交失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("提交失败，请稍后再试！")
        })
    }

    handleCheckTypeChange(event){
        console.log(event)
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });



        const formattedDate = Moment().format('YYYY-MM-DD');
        if(value == 1 && (this.state.activeStep == null || this.state.activeStep == 1)){
            this.setState({
                ppdCheckTime: formattedDate,
                ecCheckTime: null,
                igraCheckTime: null,
            })
        }
        if(value == 2 && (this.state.activeStep == null || this.state.activeStep == 1)){
            this.setState({
                ppdCheckTime: null,
                ecCheckTime: formattedDate,
                igraCheckTime: null,
            })
        }
        if(value == 4 && (this.state.activeStep == null || this.state.activeStep == 1)){
            this.setState({
                ppdCheckTime: null,
                ecCheckTime: null,
                igraCheckTime: formattedDate,
            })
        }

        if(value == 1 && this.state.activeStep > 1){//填报或更新结果
            this.setState({
                ecCheckTime: null,
                ecCheckResultTime: null,
                ecReact: null,
                ecHardenDiameterX: null,
                ecHardenDiameterY: null,
                ecHardenDiameter: null,
                ecBlister: null,
                ecDoubleLoop: null,
                ecNecrosis: null,
                ecLymphangitis: null,
                ecResult: null,
                ecRemark: null,
                igraCheckTime: null,
                igraCheckResultTime: null,
                igraResult: null,
            })
        }
        if(value == 2 && this.state.activeStep > 1){//填报或更新结果
            this.setState({
                ppdCheckTime: null,
                ppdCheckResultTime: null,
                ppdReact: null,
                ppdHardenDiameterX: null,
                ppdHardenDiameterY: null,
                ppdHardenDiameter: null,
                ppdBlister: null,
                ppdDoubleLoop: null,
                ppdNecrosis: null,
                ppdLymphangitis: null,
                ppdResult: null,
                ppdRemark: null,
                igraCheckTime: null,
                igraCheckResultTime: null,
                igraResult: null,
            })
        }
        if(value == 4 && this.state.activeStep > 1){//填报或更新结果
            this.setState({
                ppdCheckTime: null,
                ppdCheckResultTime: null,
                ppdReact: null,
                ppdHardenDiameterX: null,
                ppdHardenDiameterY: null,
                ppdHardenDiameter: null,
                ppdBlister: null,
                ppdDoubleLoop: null,
                ppdNecrosis: null,
                ppdLymphangitis: null,
                ppdResult: null,
                ppdRemark: null,
                ecCheckTime: null,
                ecCheckResultTime: null,
                ecReact: null,
                ecHardenDiameterX: null,
                ecHardenDiameterY: null,
                ecHardenDiameter: null,
                ecBlister: null,
                ecDoubleLoop: null,
                ecNecrosis: null,
                ecLymphangitis: null,
                ecResult: null,
                ecRemark: null,
            })
        }
    }


    render() {
        //get react-material css styles
        const { classes } = this.props;


        var that = this;
        let pics = '';

        let fileList = new Array();

        //上传
        const uploadFile = {
            name: 'file',
            action: Api.getRootUrl()+'/api/uploadFile',
            multiple: false,
            beforeUpload: (file) => {
                const isPNG = file.type === 'image/png';
                const isJPG = file.type === 'image/jpeg';
                if (!isPNG && !isJPG) {
                  message.error(`只支持PNG和JPG格式！`);
                  return false;
                }
                return isPNG || isJPG || Upload.LIST_IGNORE;
            },
            onChange(info) {
                console.log(pics)
                console.log(info)
                if (info.file.status !== 'uploading') {
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} 上传成功`);
                    (that.state.pic || []).map((item, index) => {
                        fileList.push(item)
                    })
                    info.fileList.map((item, index) => {
                        if (fileList.indexOf(item.response.data) < 0) {
                            fileList.push(item.response.data)

                            let params = {
                                id: that.state.id,
                                patientId: that.state.patientId,
                                imgUrl: item.response.data,
                                token: localStorage.getItem("token"),
                                userId: localStorage.getItem("userId"),
                            }
                            that.addScreenImage(params)
                        }

                    })
                    that.setState({
                        pic: fileList
                    })


                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} 上传失败.`);
                }
                return pics;
            }
        };

        return (
            <Switch>
                <Grid className={classes.root}>

                    <Dialog
                        open={this.state.open}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >

                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                确认删除该张照片吗？
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.confirmRemoveImg}>确认</Button>
                            <Button onClick={this.handleClose} autoFocus>
                                取消
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <Grid item xs={12} className={classes.Patient}>
                        <img src={returnImg} className={classes.jiantou} onClick={this.handleReturnClick} />
                        {/*
                        <Grid container justify="center" className={classes.headers}>
                            <h3 className={classes.headTitle}>筛查流程</h3>
                        </Grid>
                        */}

                        <Grid item xs={12} justify="center" className={classes.headers}><h3 className={classes.headTitle}>筛查流程</h3></Grid>
                        <Grid item xs={3} className={classes.headers} onClick={this.handleClickOk}><h4 className={classes.next}>提交</h4> </Grid>
                    </Grid>




                    <Grid className={classes.titleDiv}>
                        <Grid item xs={12} container direction="row" alignContent="center" justify="flex-start" GridWrap='nowrap'

                            className={classes.paperList}>
                            <Grid item xs={12} container direction="column" justify="center">
                                <Stepper alternativeLabel activeStep={this.state.activeStep}>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid className={classes.roots}>
                        <Typography h5 noWrap className={classes.spacemargin}></Typography>
                        <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    学校：<span className={classes.title}>{this.state.schoolName}</span>
                                </Typography>
                            </Grid>

                        </Grid>

                        <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    姓名：<span className={classes.title}>{this.state.name}</span>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.titleBold} gutterBottom>
                                    班级：<span className={classes.title}>{this.state.className}</span>
                                </Typography>
                            </Grid>

                        </Grid>

                        <hr className={classes.hr}></hr>



                        {/*查验表*/}
                        <div className={this.state.activeStep == 0 ? classes.ask : classes.hideMe}>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        问诊筛查结果：
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>

                                <RadioGroup row name="askResult" value={this.state.askResult} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="正常" checked={this.state.askResult == 1} />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="异常，中止后续筛查" checked={this.state.askResult == 2} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}></div>
                            <div className={classes.space}></div>
                            <div className={classes.space}></div>

                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                        问诊筛查内容：
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 1、既往结合病史（含肺外结核）：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasTuberculosis" value={this.state.hasTuberculosis}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasTuberculosis == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasTuberculosis == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>


                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 2、家庭成员或经常接触的亲戚朋友中、原同班师生或同宿舍学生有无结核病患者：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasContactTuberculosis" value={this.state.hasContactTuberculosis}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasContactTuberculosis == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasContactTuberculosis == 0} />
                                </RadioGroup>
                            </Grid>

                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 3、连续咳嗽或咳痰超过2周：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasCoughWeek" value={this.state.hasCoughWeek}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasCoughWeek == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasCoughWeek == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 4、咯血或血痰：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasCoughBlood" value={this.state.hasCoughBlood}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasCoughBlood == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasCoughBlood == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 5、胸痛、胸闷及气短：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasChestPain" value={this.state.hasChestPain}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasChestPain == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasChestPain == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 6、反复低热：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasRepeatFever" value={this.state.hasRepeatFever}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasRepeatFever == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasRepeatFever == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>


                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 7、盗汗：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasSweat" value={this.state.hasSweat}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasSweat == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasSweat == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 8、消瘦或体重下降：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasLostWeight" value={this.state.hasLostWeight}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasLostWeight == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasLostWeight == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 9、乏力、食欲减退：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasWeak" value={this.state.hasWeak}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasWeak == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasWeak == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 10、其他肺结核可疑症状：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasIssueOthers" value={this.state.hasIssueOthers}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasIssueOthers == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasIssueOthers == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>
                            <Grid container justifyContent="flex-start" >
                                <Grid item xs={12}>
                                    <Typography className={classes.title} style={{ textAlign: 'left' }} gutterBottom>如有,请输入其他症状详细内容：</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <Grid item xs={12}>
                                    <Input name="issueOthers" value={this.state.issueOthers} className={classes.inputFull}
                                        onChange={this.handleInputChange} />
                                </Grid>
                            </Grid>

                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 11、急性传染病（如麻疹、百日咳、流行性感冒、肺炎等）：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasEpidemic" value={this.state.hasEpidemic}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasEpidemic == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasEpidemic == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 12、急性眼结膜炎：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasConjunctivitis" value={this.state.hasConjunctivitis}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasConjunctivitis == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasConjunctivitis == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>


                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 13、急性中耳炎：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasTympanitis" value={this.state.hasTympanitis}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasTympanitis == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasTympanitis == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 14、全身性皮肤病：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasSkinDisease" value={this.state.hasSkinDisease}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasSkinDisease == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasSkinDisease == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 15、过敏体质：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasAllergic" value={this.state.hasAllergic}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasAllergic == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasAllergic == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>

                            <Grid container justifyContent="flex-start" >
                                <Typography className={classes.textDiv}> 16、判定不适合进行皮肤试验的其他情况：  </Typography>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <RadioGroup row name="hasOtherTaboo" value={this.state.hasOtherTaboo}
                                    onChange={this.handleInputChange}>
                                    <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.hasOtherTaboo == 1} />
                                    <FormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.hasOtherTaboo == 0} />
                                </RadioGroup>
                            </Grid>
                            <div className={classes.space}> </div>
                            <Grid container justifyContent="flex-start" >
                                <Grid item xs={12}>
                                    <Typography className={classes.title} style={{ textAlign: 'left' }} gutterBottom>如有,请输入其他情况详细内容：</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-start" >
                                <Grid item xs={12}>
                                    <Input name="otherTaboo" value={this.state.otherTaboo} className={classes.inputFull}
                                        onChange={this.handleInputChange} />
                                </Grid>
                            </Grid>

                            <div className={classes.space}> </div>
                            <div className={classes.space}> </div>
                        </div>

                        {/*注射化验项选择，自动默认事件*/}
                        <div className={this.state.activeStep > 0 ? classes.ask : classes.hideMe}>
                            <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                <Grid item>
                                    <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                        <Grid>化验类型：</Grid>
                                        <RadioGroup row name="checkType" value={this.state.checkType}
                                            onChange={this.handleCheckTypeChange}>
                                            <FormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="PPD" checked={this.state.checkType == 1} />
                                            <FormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="EC" checked={this.state.checkType == 2} />
                                            <FormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio />} label="IGRA" checked={this.state.checkType == 4} />
                                        </RadioGroup>
                                    </Typography>
                                </Grid>
                            </Grid>
                        
                        </div>

                        {/*PPD*/}
                        <div className={this.state.activeStep > 0 ? '' : classes.hideMe}>
                            <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                <Grid item>
                                    <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                        <Grid>PPD检查时间：</Grid>
                                        <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={false} className="date-picker-border" defaultValue={this.state.ppdCheckTime == null ? null : Moment(this.state.ppdCheckTime)} value={this.state.ppdCheckTime == null ? null : Moment(this.state.ppdCheckTime)}
                                            onChange={this.pickerPpdCheckTime} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <div className={this.state.activeStep >= 2 ? '' : classes.hideMe}>
                                <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                    <Grid item>
                                        <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                            <Grid>PPD结果时间：</Grid>
                                            <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                                allowClear={false} className="date-picker-border" defaultValue={this.state.ppdCheckResultTime == null ? null : Moment(this.state.ppdCheckResultTime)} value={this.state.ppdCheckResultTime == null ? null : Moment(this.state.ppdCheckResultTime)}
                                                onChange={this.pickerPpdCheckResultTime} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            PPD反应：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="ppdReact" value={this.state.ppdReact} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.ppdReact == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.ppdReact == 0} />
                                    </RadioGroup>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                            <span className={classes.titleBold}>硬结横径（mm）：</span>
                                            <Input name="ppdHardenDiameterX" value={this.state.ppdHardenDiameterX} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                            <span className={classes.titleBold}>硬结纵径（mm）：</span>
                                            <Input name="ppdHardenDiameterY" value={this.state.ppdHardenDiameterY} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                            <span className={classes.titleBold}>硬结平均直径：</span>
                                            <Input name="ppdHardenDiameter" value={this.state.ppdHardenDiameter} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            是否水泡：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="ppdBlister" value={this.state.ppdBlister} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ppdBlister == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ppdBlister == 0} />
                                    </RadioGroup>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            是否双圈：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="ppdDoubleLoop" value={this.state.ppdDoubleLoop} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ppdDoubleLoop == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ppdDoubleLoop == 0} />
                                    </RadioGroup>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            是否坏死：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="ppdNecrosis" value={this.state.ppdNecrosis} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ppdNecrosis == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ppdNecrosis == 0} />
                                    </RadioGroup>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            是否淋巴管炎：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="ppdLymphangitis" value={this.state.ppdLymphangitis} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ppdLymphangitis == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ppdLymphangitis == 0} />
                                    </RadioGroup>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            PPD结果判定：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="ppdResult" value={this.state.ppdResult} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="阴性" checked={this.state.ppdResult == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="一般阳性" checked={this.state.ppdResult == 2} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio />} label="中阳" checked={this.state.ppdResult == 3} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="4" control={<StyledRadio />} label="强阳" checked={this.state.ppdResult == 4} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="未检查" checked={this.state.ppdResult == 0} />
                                    </RadioGroup>
                                </Grid>
                            </div>

                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                        <span className={classes.titleBold}>备注：</span>
                                        <Input name="ppdRemark" value={this.state.ppdRemark} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                    </Typography>
                                </Grid>
                            </Grid>


                            {/*EC*/}
                            <hr className={classes.hr}></hr>
                            <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                <Grid item>
                                    <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                        <Grid>EC检查时间：</Grid>
                                        <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={false} className="date-picker-border" defaultValue={this.state.ecCheckTime == null ? null : Moment(this.state.ecCheckTime)} value={this.state.ecCheckTime == null ? null : Moment(this.state.ecCheckTime)}
                                            onChange={this.pickerEcCheckTime} />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <div className={this.state.activeStep >= 2 ? '' : classes.hideMe}>
                                <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                    <Grid item>
                                        <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                            <Grid>EC结果时间：</Grid>
                                            <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                                allowClear={false} className="date-picker-border" defaultValue={this.state.ecCheckResultTime == null ? null : Moment(this.state.ecCheckResultTime)} value={this.state.ecCheckResultTime == null ? null : Moment(this.state.ecCheckResultTime)}
                                                onChange={this.pickerEcCheckResultTime} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            EC反应：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="ecReact" value={this.state.ecReact} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="有" checked={this.state.ecReact == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="无" checked={this.state.ecReact == 0} />
                                    </RadioGroup>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                            <span className={classes.titleBold}>EC硬结横径（mm）：</span>
                                            <Input name="ecHardenDiameterX" value={this.state.ecHardenDiameterX} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                            <span className={classes.titleBold}>EC硬结纵径（mm）：</span>
                                            <Input name="ecHardenDiameterY" value={this.state.ecHardenDiameterY} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                            <span className={classes.titleBold}>EC硬结或红晕较大值的平均直径(mm)：</span>
                                            <Input name="ecHardenDiameter" value={this.state.ecHardenDiameter} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            是否水泡：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="ecBlister" value={this.state.ecBlister} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ecBlister == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ecBlister == 0} />
                                    </RadioGroup>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            是否双圈：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="ecDoubleLoop" value={this.state.ecDoubleLoop} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ecDoubleLoop == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ecDoubleLoop == 0} />
                                    </RadioGroup>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            是否坏死：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="ecNecrosis" value={this.state.ecNecrosis} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ecNecrosis == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ecNecrosis == 0} />
                                    </RadioGroup>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            是否淋巴管炎：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="ecLymphangitis" value={this.state.ecLymphangitis} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="是" checked={this.state.ecLymphangitis == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="否" checked={this.state.ecLymphangitis == 0} />
                                    </RadioGroup>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            EC结果判定：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="ecResult" value={this.state.ecResult} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="阳性" checked={this.state.ecResult == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="阴性" checked={this.state.ecResult == 2} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="未检查" checked={this.state.ecResult == 0} />
                                    </RadioGroup>
                                </Grid>
                            </div>


                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                        <span className={classes.titleBold}>备注：</span>
                                        <Input name="ecRemark" value={this.state.ecRemark} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/*IGRA*/}
                            <hr className={classes.hr}></hr>
                            <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                <Grid item>
                                    <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                        <Grid>IGRA检查时间：</Grid>
                                        <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                            allowClear={false} className="date-picker-border" defaultValue={this.state.igraCheckTime == null ? null : Moment(this.state.igraCheckTime)} value={this.state.igraCheckTime == null ? null : Moment(this.state.igraCheckTime)}
                                            onChange={this.pickerIgraCheckTime} />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <div className={this.state.activeStep >= 2 ? '' : classes.hideMe}>
                                <Grid container justify="space-between" wrap="nowrap" spacing={2} >
                                    <Grid item>
                                        <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                            <Grid>IGRA结果时间：</Grid>
                                            <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                                allowClear={false} className="date-picker-border" defaultValue={this.state.igraCheckResultTime == null ? null : Moment(this.state.igraCheckResultTime)} value={this.state.igraCheckResultTime == null ? null : Moment(this.state.igraCheckResultTime)}
                                                onChange={this.pickerIgraCheckResultTime} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                    <Grid item>
                                        <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                            IGRA结果判定：
                                        </Typography>
                                    </Grid>
                                    <RadioGroup row name="igraResult" value={this.state.igraResult} onChange={this.handleInputChange}>
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="1" control={<StyledRadio />} label="阳性" checked={this.state.igraResult == 1} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="2" control={<StyledRadio />} label="阴性" checked={this.state.igraResult == 2} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="3" control={<StyledRadio />} label="不确定" checked={this.state.igraResult == 3} />
                                        <BeautyFormControlLabel className={classes.OptionDiv} value="0" control={<StyledRadio />} label="未检查" checked={this.state.ecResult == 0} />
                                    </RadioGroup>
                                </Grid>
                            </div>

                            <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                        <span className={classes.titleBold}>备注：</span>
                                        <Input name="igraRemark" value={this.state.igraRemark} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            





                            <Grid item className={this.state.activeStep > 1?'':classes.hideMe}>
                                <Typography className={classes.photosDIV} color="textSecondary" gutterBottom>
                                    上传照片：<Upload {...uploadFile} showUploadList={{ "showDownloadIcon": false }} name="file">
                                        <Button style={{ display: "" }} className={classes.buttonsTwo}>
                                            <Icon type="upload" /> 添加照片
                                        </Button>
                                    </Upload>

                                    <ImageList rowHeight={180} className={classes.imageList}>
                                        {(this.state.pic || []).map((item) => (
                                            <ImageListItem key={item}>
                                                <img src={item} alt={item.title} />
                                                <ImageListItemBar
                                                    title={item.title}
                                                    onClick={() => this.clickeImg(item)}
                                                    subtitle={<span>点击删除</span>}
                                                    actionIcon={
                                                        <IconButton aria-label={`info about ${item.title}`} className={classes.icon}>
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                    }
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>

                                </Typography>
                            </Grid>

                        </div>


                    </Grid>

                </Grid>

            </Switch >
        );
    }

}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (userInfo) => {
            // dispatch(loginRequest(userInfo))
        },
    }
}


//css region
const styles = theme => ({
    root: {
        flexGrow: 1,
        //overflow: 'hidden',
        backgroundColor: '#ffffff',
        minHeight: '100vh',
        // padding: theme.spacing(0, 3),
    },
    // spacemargin: {
    //   margin: theme.spacing(1),

    // },
    roots: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    Patient: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '14vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },
    jiantou: {
        height: '4vw',
        marginLeft: theme.spacing(2),
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '85vw',
        height: '14vw',
    },
    headTitle: {
        color: '#FFFFFF'
    },
    titleDiv: {
        margin: '32px',
    },
    rootInput: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    dataDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "90vw",
        height: "13.3vw",
        fontSize: "4.2vw",
    },
    space: {
        height: '5px',
        margin: '5px',
    },
    hideMe: {
        display: 'none'
    },
    ask: {
        padding: '1.5rem',
    },
    titleBold: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333'
    },
    title: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '500',
        color: '#333333'
    },
    // 时间组件样式
    titleBoldBox: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333',
        display: 'flex',
        // justifyContent: 'center'
    },
    pickersInputBorder: {
        border: 0,
    },
    timeContentBoxRight: {
        width: "60%"
    },
    textFieldContent: {
        width: '60vw'
    },
    buttonsTwo: {
        width: '25vw',
        height: '8vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '3vw',
        marginBottom: 'theme.spacing(2)',

        margin: theme.spacing(1),
        position: 'relative',
        border: '1px solid #00C9E4',
        borderRadius: '5px',
        padding: '4px 12px',
        overflow: 'hidden',
        color: '#fff',
        textDecoration: 'none',
        lineHeight: '8vw'
    },

});
//end css region

//here we inject redux and react-material css styles
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScreenCheckView));